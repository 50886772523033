<template>
  <div>
    <header-menu ref="HeaderMenu"></header-menu>
    <div class="view-content">
      <div class="banner">
        <div class="desc__box text_left">
          <h1 class="banner_title font-size_60">
            <div>医药主数据平台</div>
          </h1>
          <p class="banner_desc font-size_20">
            帮助企业清洗沉淀客户主数据，依托强大的行业平台数据库，可清洗主数据标准名称及省市区地址等各类字段，提高企业客户真实性。
          </p>
        </div>
      </div>

      <div class="l_r_space t__space">
        <h2 class="nav__title">主数据管理</h2>
        <p class="nav__desc" style="padding: 0.15rem 0 0.4rem 0">
          通过“数据仓库”构建企业全局共享数据集，形成企业数据标准，并建立数据管理和数据服务机制。
        </p>
        <div>
          <a-row :gutter="30" type="flex">
            <a-col
              style="padding-bottom: 15px"
              :xs="24"
              :sm="12"
              :md="12"
              :lg="12"
              :xl="6"
              :xxl="6"
              v-for="(item, index) in dataList"
              :key="index"
            >
              <a-card>
                <img :src="item.img_url" alt="" class="view_width-full" />
                <div>
                  <h3 class="col_title font-size_26">{{ item.title }}</h3>
                  <p class="col_desc font-size_16">{{ item.desc }}</p>
                </div>
              </a-card>
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="l_r_space t__space bgc_f4fbff">
        <h2 class="nav__title">行业主数据库</h2>
        <p class="nav__desc" style="padding: 0.15rem 0 1rem 0">
          提供统一的数据交换通道，同时为数据中心提供可靠的数据采集通道
        </p>
        <div style="padding-bottom: 0.8rem">
          <a-row type="flex">
            <a-col
              style="padding-bottom: 15px"
              :xs="12"
              :sm="12"
              :md="8"
              :lg="8"
              :xl="4"
              :xxl="4"
              v-for="(item, index) in industryList"
              :key="index"
            >
              <img
                :src="item.img_url"
                :alt="item.title"
                style="width: 100%; max-width: 180px"
              />
              <h3 class="industry_title b_b_space font-size_26">
                {{ item.title }}
              </h3>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "@/components/Footer";
import HeaderMenu from "@/components/HeaderMenu";
import platform_1 from "@/assets/img/png/platform_1.png";
import platform_2 from "@/assets/img/png/platform_2.png";
import platform_3 from "@/assets/img/png/platform_3.png";
import platform_4 from "@/assets/img/png/platform_4.png";
import platform_5 from "@/assets/img/png/platform_5.png";
import platform_6 from "@/assets/img/png/platform_6.png";
import platform_7 from "@/assets/img/png/platform_7.png";
import platform_8 from "@/assets/img/png/platform_8.png";
import platform_9 from "@/assets/img/png/platform_9.png";
import platform_10 from "@/assets/img/png/platform_10.png";
export default {
  name: "masterDataPlatform",
  beforeCreate() {},
  components: { Footer, HeaderMenu },
  data() {
    return {
      dataList: [
        {
          title: "行业主数据库",
          desc: "提供强大的行业主数据库，根据工商局数据及卫健委数据定期更新",
          img_url: platform_1,
        },
        {
          title: "主数据管理",
          desc: "维护客户资料，沉淀企业自己的主数据库",
          img_url: platform_2,
        },
        {
          title: "主数据清洗",
          desc: "帮助企业清洗主数据，找出重复客户及虚假客户",
          img_url: platform_3,
        },
        {
          title: "客户画像",
          desc: "多维度分析客户信息资质，帮助企业描绘客户画像",
          img_url: platform_4,
        },
      ],
      industryList: [
        { title: "百万级实名医生", img_url: platform_5 },
        { title: "30000+医院", img_url: platform_6 },
        { title: "56W+基层医疗", img_url: platform_7 },
        { title: "68W+药店", img_url: platform_8 },
        { title: "8000+连锁总部", img_url: platform_9 },
        { title: "10000+配送商", img_url: platform_10 },
      ],
    };
  },
  created() {},
  methods: {
    handleSubmit() {},
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/style/color.scss";
.col_title {
  color: $color-lightMaxBlue;
  padding: 0.25rem 0 0.1rem 0;
  margin-bottom: 0;
}
.col_desc {
  color: $color-fixGray;
  margin-bottom: 0;
}
.industry_title {
  padding-top: 0.3rem;
}
.banner {
  width: 100%;
  position: relative;
  background: url("~@/assets/img/png/master_banner.png");
  background-size: 100% 100%;
  height: 8rem;
  overflow: hidden;
  .desc__box {
    position: absolute;
    top: 1.5rem;
    left: 13.5%;
    .banner_title {
      margin-bottom: 0;
    }
    .banner_desc {
      width: 4.5rem;
      padding-top: 0.3rem;
      margin-bottom: 0;
    }
  }
}
</style>
